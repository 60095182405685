html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.buttoned-table td {
    line-height: 38px;
}

.buttoned-table.table-sm {
    line-height: 26px;
    .button-column {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .btn {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
