



















































































$main-bg: #242422;
$main-color: #f1f1f0;
#menubar {
    background: $main-bg;
    color: $main-color;
    .nav-link {
        color: $main-color;
    }
}
#app.not-fullscreen {
    padding: 10px;
}
